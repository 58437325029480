import React from 'react';
import { Table } from 'reactstrap';
import './BusinessApproval.scss';
import BusinessApprovalStatusSelect from './BusinessApprovalStatusSelect';
import { IDealer } from 'app/shared/model/dealer.model';
import { useHistory } from 'react-router-dom';

const BusinessApproval = props => {
  let history = useHistory();

  const goToLeadDetails = (e, lead) => {
    if (e.target.nodeName !== 'SPAN' && e.target.nodeName !== 'IMG') {

    }
  };

  return (
    <Table responsive borderless hover className="leads-table">
      <thead>
        <tr className="leads-table-header">
          <th>Name</th>
          <th>Business Name</th>
          <th>City</th>
          <th>State</th>
          <th>Skills</th>
          <th>Subscribed</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {props.members.map((member, index) => (
          <tr className="leads-table-item" key={member.id} onClick={e => goToLeadDetails(e, member)}>
            <td>{member.name}</td>
            <td>{member.businessName}</td>
            <td>{member.city}</td>
            <td>{member.state}</td>
            <td>{member.skills}</td>
            <td>{member.subscriptionId ? 'Subscribed' : 'Not Subscribed'}</td>
            <td>
              <BusinessApprovalStatusSelect member={member}  type={props.type}/>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default BusinessApproval;
