import React, { useEffect, useState } from 'react';
import BaseLayout from '../BaseLayout';
import SelectButton from 'app/components/SelectButton/SelectButton';
import NotificationItem from 'app/components/NotificationItem/NotificationItem';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { Row, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { getNotifications } from 'app/entities/notification/notification.reducer';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { markAllNotifiationsAsReadUnread } from 'app/entities/notification/notification.reducer';
import { markNotificationAsRead, readUnreadNotification } from 'app/entities/notification/notification.reducer';
import { NOTIFICATION_STATUS } from 'app/config/constants';
import AppSpinner from 'app/components/Spinner/Spinner';
import NoDataBanner from 'app/components/NoDataBanner/NoDataBanner';
import ReactPaginate from 'react-paginate';
import MD5 from 'crypto-js/md5';
import './notifications.scss';

export interface INotificationsProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
  markAllNotifiationsAsReadUnread: any;
  readUnreadNotification: any;
}

const Notifications = (props: INotificationsProps) => {
  const [mailsha1, setMailsha1] = useState(MD5(props.account.email).toString());
  let history = useHistory();
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(
      {
        itemsPerPage: ITEMS_PER_PAGE,
        sort: 'dateCreated',
        order: 'desc',
        activePage: 0,
      },
      props.location.search
    )
  );
  const [isSelectModeEnable, setIsSelectModeEnable] = useState(false);
  const [selectedNotifications, setSelectedNotifications] = useState<number[]>([]);

  const getAllNotifications = () => {
    props.getNotifications(
      paginationState.activePage - 1,
      paginationState.itemsPerPage,
      `${paginationState.sort},${paginationState.order}`
    );
  };

  const sortEntities = () => {
    getAllNotifications();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get('sort');
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage.selected,
    });

  const enableSelectMode = () => {
    setIsSelectModeEnable(true);
  };

  const disableSelectMode = () => {
    setIsSelectModeEnable(false);
    setSelectedNotifications([]);
  };

  const markAllNotifiationsAsReadOrUnread = (status: boolean) => {
    let notificationIds = [];
    props.notificationsList.map(item => notificationIds.push(item.id));
    // mark all as read on the frontend without page reloading
    props.markNotificationAsRead(notificationIds, status ? NOTIFICATION_STATUS.READ : NOTIFICATION_STATUS.UNREAD);
    // request to the backend
    props.markAllNotifiationsAsReadUnread(status);
    setIsSelectModeEnable(false);
  };

  const selectNotification = (notificationId: number) => {
    const notificationsArray = [...selectedNotifications];

    if (notificationsArray.includes(notificationId)) {
      const result = notificationsArray.filter(item => item !== notificationId);
      setSelectedNotifications(result);
    } else {
      setSelectedNotifications([...notificationsArray, notificationId]);
    }
  };

  const readSelectedNotifications = () => {
    props.readUnreadNotification(selectedNotifications, true);
    props.markNotificationAsRead(selectedNotifications, NOTIFICATION_STATUS.READ);
    setSelectedNotifications([]);
    setIsSelectModeEnable(false);
  };

  const unreadSelectedNotifications = () => {
    props.readUnreadNotification(selectedNotifications, false);
    props.markNotificationAsRead(selectedNotifications, NOTIFICATION_STATUS.UNREAD);
    setSelectedNotifications([]);
    setIsSelectModeEnable(false);
  };

  return (
    <BaseLayout
      title={'Notifications'}
      button={
        <Button onClick={() => history.push('/notification-settings')} className="notification-settings-link-button">
          Notification Settings
        </Button>
      }
      selectButton={
        <SelectButton
          title={isSelectModeEnable ? 'Cancel' : 'Select'}
          onClick={isSelectModeEnable ? disableSelectMode : enableSelectMode}
        />
      }
    >
      {props.loading && <AppSpinner />}
      {isSelectModeEnable && (
        <div className="read-buttons-container">
          <button
            className="read-button"
            onClick={selectedNotifications.length ? readSelectedNotifications : () => markAllNotifiationsAsReadOrUnread(true)}
          >
            {selectedNotifications.length ? 'Read' : 'Read All'}
          </button>
          <button
            className="read-button unread-button"
            onClick={selectedNotifications.length ? unreadSelectedNotifications : () => markAllNotifiationsAsReadOrUnread(false)}
          >
            {selectedNotifications.length ? 'UnRead' : 'UnRead All'}
          </button>
        </div>
      )}
      {!props.loading &&
        props.notificationsList.map(notification => (
          <NotificationItem
            item={notification}
            key={notification.id}
            isSelectModeEnable={isSelectModeEnable}
            selectedNotifications={selectedNotifications}
            selectNotification={selectNotification}
            mailsha1={mailsha1}
          />
        ))}
      {!props.notificationsList.length && !props.loading && <NoDataBanner title="You have no Notifications to display yet!" />}
      {props.totalItems ? (
        <div
          className={
            props.notificationsList && props.notificationsList.length > paginationState.itemsPerPage ? 'pagination-container' : 'd-none'
          }
        >
          <Row className="justify-content-center">
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePagination}
              pageRangeDisplayed={5}
              pageCount={Math.ceil(props.totalItems / paginationState.itemsPerPage)}
              previousLabel="<"
              renderOnZeroPageCount={null}
              forcePage={paginationState.activePage}
              className="pagination"
              pageClassName="pagination-page"
              previousClassName="pagination-nex-prev-button"
              nextClassName="pagination-nex-prev-button"
              disabledClassName="pagination-page-disabled"
              activeClassName="pagination-active-page"
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </BaseLayout>
  );
};

const mapStateToProps = ({ notification, authentication, project }: IRootState) => ({
  notificationsList: notification.notifications,
  loading: notification.loading,
  totalItems: notification.totalNotifications,
  account: authentication.account,
});

const mapDispatchToProps = {
  getNotifications,
  markAllNotifiationsAsReadUnread,
  markNotificationAsRead,
  readUnreadNotification,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
