import React, { memo } from 'react';
import { Button } from 'reactstrap';
import './mainThemeButton.scss';

interface IMainThemeButton {
  title: string;
  onClick?: Function;
  iconName: string;
  fontSize?: number;
  disabled?: boolean;
}

const MainThemeButton = memo((props: IMainThemeButton) => {
  return (
    <Button className="main-theme-button" onClick={props.onClick} disabled={props.disabled}>
      <img src={`../../../content/images/icons/${props.iconName}.svg`} width="20" height="20" className="main-theme-button-icon" />
      <span style={{ fontSize: props.fontSize }}>{props.title}</span>
    </Button>
  );
});

export default MainThemeButton;
