import React, { Component } from 'react';
import './baseLayout.scss';

interface IBaseLayout {
  title: String;
  filter?: Component;
  search?: JSX.Element;
  createButton?: JSX.Element;
  selectButton?: JSX.Element;
  checkbox?: JSX.Element;
  backButton?: JSX.Element;
  datePicker?: JSX.Element;
  button?: JSX.Element;
  centerElement?: JSX.Element;
  children?: any;
  toggle?: any;
}

const BaseLayout = (props: IBaseLayout) => {
  return (
    <div className="base-layout-container">
      <div className="base-layout-header">
        <h6 className="page-title">{props.title}</h6>
        <div className="base-layout-center-element-container">{props.centerElement}</div>
        <div className="base-layout-filters-container">
          {props.toggle}
          {props.checkbox}
          {props.datePicker}
          {props.button}
          {props.search}
          {props.filter}
          {props.createButton}
          {props.selectButton}
          {props.backButton}
        </div>
      </div>
      {props.children}
    </div>
  );
};

export default BaseLayout;
