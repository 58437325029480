// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../content/images/icons/less-symbol.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".back-to-project-submission-button {\n  border: none;\n  background: none;\n  font-family: \"Poppins\", sans-serif;\n  font-weight: 400;\n  font-size: 18px;\n  -webkit-text-decoration-line: underline;\n          text-decoration-line: underline;\n  color: #4e8eb3 !important;\n  padding: 0 !important;\n}\n.back-to-project-submission-button::before {\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  margin-right: 10px;\n  position: relative;\n  bottom: -2px;\n}\n\n.project-submission-log-configuration-link {\n  width: -webkit-fit-content;\n  width: -moz-fit-content;\n  width: fit-content;\n  padding: 5px 10px;\n  border-radius: 10px;\n  color: white;\n  font-weight: 400;\n  background-color: #4e8eb3;\n  margin-right: 5px;\n  margin-bottom: 5px;\n}\n\n.project-submission-details-project-info {\n  width: 100%;\n  background: #ffffff;\n  border-radius: 21px;\n  padding: 10px 30px 20px 30px;\n  display: flex;\n  justify-content: space-between;\n  margin: 30px 0;\n}\n.project-submission-details-project-info + .collapse-container {\n  flex-direction: column;\n}\n\n.project-submission-details-project-info-column {\n  width: 42%;\n  display: flex;\n  flex-direction: column;\n}\n\n.project-submission-details-project-info-column-row {\n  width: 100%;\n  display: flex;\n  align-items: center;\n}\n\n.project-submission-details-project-info-column-row-data {\n  width: 50%;\n  font-family: \"Poppins\", sans-serif;\n  font-weight: 500;\n  font-size: 16px;\n  color: #000000;\n  margin: 10px 0;\n  text-align: left;\n}\n.project-submission-details-project-info-column-row-data + .email {\n  color: #4e8eb3;\n}\n.project-submission-details-project-info-column-row-data + .project-file {\n  color: #d5241f;\n}", ""]);
// Exports
module.exports = exports;
