import React, { useState, lazy, Suspense, useCallback } from 'react';
import BaseLayout from '../BaseLayout';
import Search from 'app/components/Search/Search';
import InventoryFolder from 'app/components/InventoryFolder/InventoryFolder';
import Toggle from 'app/components/Toggle/Toggle';
import { RouteComponentProps } from 'react-router-dom';
import { createEntity } from 'app/entities/folder/folder.reducer';
const InventoryProject = lazy(() => import('app/components/InventoryProject/InventoryProject'));
const CreateFolderModal = lazy(() => import('app/components/CreateFolderModal/CreateFolderModal'));
const FolderSelectModal = lazy(() => import('app/components/InventoryProductCard/FolderSelectModal'));
import { operateUserConfiguration } from 'app/entities/user-configuration/user-configuration.reducer';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { IFolder } from 'app/shared/model/folder.model';
import './myInventory.scss';

export interface IFolderProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
  operateUserConfiguration: any;
}

const MyInventory = (props: IFolderProps) => {
  const initialMultipleOperationRequestState = {
    userConfigurationId: null,
    userConfigurationIds: [],
    sourceFolderId: null,
    destinationFolderId: null,
    operationType: 'COPY_ALL',
  };

  const [inventoryTogglePosition, setInventoryTogglePosition] = useState(true);
  const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isMultipleSelectModeActive, setIsMultipleSelectModeActive] = useState(false);
  const [isSelectFolderModalOpen, setIsSelectFolderModalOpen] = useState(false);
  const [multipleOperationRequest, setMultipleOperationRequest] = useState({ ...initialMultipleOperationRequestState });

  const createFolder = useCallback(
    (entity: IFolder) => {
      props.createEntity(entity);
      setIsCreateFolderModalOpen(false);
    },
    [props.createEntity]
  );

  const selectInventoryCard = useCallback(
    (inventoryId: number, folderId: number) => {
      const inventoriesArray = [...multipleOperationRequest.userConfigurationIds];

      if (inventoriesArray.includes(inventoryId)) {
        const result = inventoriesArray.filter(item => item !== inventoryId);
        setMultipleOperationRequest({
          ...multipleOperationRequest,
          userConfigurationIds: result,
        });
      } else {
        setMultipleOperationRequest({
          ...multipleOperationRequest,
          userConfigurationIds: [...inventoriesArray, inventoryId],
          sourceFolderId: folderId,
        });
      }
    },
    [multipleOperationRequest]
  );

  const deleteMultipleInventories = () => {
    props.operateUserConfiguration({ ...multipleOperationRequest, operationType: 'DELETE_ALL' });
    setIsMultipleSelectModeActive(false);
    setMultipleOperationRequest({ ...initialMultipleOperationRequestState });
  };

  return (
    <BaseLayout
      title={'My Inventory'}
      search={<Search searchValue={searchValue} setSearchValue={setSearchValue} />}
      centerElement={
        <Toggle
          rightButtonTitle="Project Inventory"
          leftButtonTitle="My Inventory"
          rightButtonActive={!inventoryTogglePosition}
          leftButtonActive={inventoryTogglePosition}
          rightButtonAction={() => setInventoryTogglePosition(!inventoryTogglePosition)}
          leftButtonAction={() => setInventoryTogglePosition(!inventoryTogglePosition)}
          loading={props.loading}
        />
      }
    >
      <div className="mt-5 create-folder-button-container">
        {!props.loading && (
          <>
            {isMultipleSelectModeActive && (
              <Button
                onClick={() => setIsSelectFolderModalOpen(true)}
                disabled={
                  multipleOperationRequest.userConfigurationIds.length === 0 ||
                  props.loading ||
                  props.updating ||
                  props.loadingUserConfiguration ||
                  props.updatingUserConfiguration
                }
                className="inventories-action-button move-invenoties-button"
              >
                Move
              </Button>
            )}
            {isMultipleSelectModeActive && (
              <Button
                onClick={() => deleteMultipleInventories()}
                disabled={
                  multipleOperationRequest.userConfigurationIds.length === 0 ||
                  props.loading ||
                  props.updating ||
                  props.loadingUserConfiguration ||
                  props.updatingUserConfiguration
                }
                className="inventories-action-button delete-invenoties-button"
              >
                Delete
              </Button>
            )}
            <Button
              className="inventories-action-button create-new-folder-button"
              onClick={() => setIsCreateFolderModalOpen(true)}
              disabled={props.loading || props.updating || props.loadingUserConfiguration || props.updatingUserConfiguration}
            >
              Create a New Folder <img src="../../../content/images/icons/circle-plus.svg" alt="plus icon" style={{ marginLeft: 10 }} />
            </Button>

            <Button
              disabled={props.loading || props.updating || props.loadingUserConfiguration || props.updatingUserConfiguration}
              onClick={() => {
                setIsMultipleSelectModeActive(!isMultipleSelectModeActive);
                setMultipleOperationRequest({
                  ...multipleOperationRequest,
                  userConfigurationIds: [],
                  sourceFolderId: null,
                });
              }}
              className="inventories-action-button select-multiple-inventories-button"
            >
              {isMultipleSelectModeActive ? 'Cancel' : 'Select Multiple'}
            </Button>
          </>
        )}
      </div>
      {inventoryTogglePosition ? (
        <InventoryFolder
          searchValue={searchValue}
          isMultipleSelectModeActive={isMultipleSelectModeActive}
          selectInventoryCard={selectInventoryCard}
          selectedInventories={multipleOperationRequest.userConfigurationIds}
        />
      ) : (
        <Suspense fallback={<span></span>}>
          <InventoryProject searchValue={searchValue} />
        </Suspense>
      )}
      <Suspense fallback={<span></span>}>
        <CreateFolderModal
          isOpen={isCreateFolderModalOpen}
          closeModal={() => setIsCreateFolderModalOpen(false)}
          createFolder={createFolder}
          title="Create Folder"
          type="createFolder"
        />
        <FolderSelectModal
          isOpen={isSelectFolderModalOpen}
          operationRequest={multipleOperationRequest}
          setOperationRequest={setMultipleOperationRequest}
          closeFolderSelectModal={() => setIsSelectFolderModalOpen(false)}
          isMultipleSelectModeActive={isMultipleSelectModeActive}
        />
      </Suspense>
    </BaseLayout>
  );
};

const mapStateToProps = state => ({
  loading: state.folder.loading,
  updating: state.folder.updating,
  loadingUserConfiguration: state.userConfiguration.loading,
  updatingUserConfiguration: state.userConfiguration.updating,
});

const mapDispatchToProps = { createEntity, operateUserConfiguration };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MyInventory);
