import React, { useState, useMemo, memo, lazy, Suspense } from 'react';
import { Table, Button, Modal } from 'reactstrap';
import { useHistory } from 'react-router';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
const SortListDropdown = lazy(() => import('../SortListDropdown/SortListDropdown'));
import useViewConfiguration from 'app/customHooks/useSharePageRedirect';
import { IProjectSubmissionLog } from 'app/shared/model/project-submission-log.model';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light-border.css';
import moment from 'moment';
import './projectSubmissionTable.scss';

interface IProjectSubmissionTable {
  projectSubmissionLogList: any;
  sort: Function;
  account: any;
  currentProject: any;
  getProject: Function;
}

const ProjectSubmissionTable = memo((props: IProjectSubmissionTable) => {
  let history = useHistory();

  const { setIsViewConfigurationButtonWasPressed, getCurrentProject } = useViewConfiguration(props);
  const [isDrawingsModalOpen, setIsDrawingsModalOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<IProjectSubmissionLog>({});

  // TODO: temporary solution to hide invalid items. Need to find the reason on the backend side
  const filteredList = useMemo(() => props.projectSubmissionLogList.filter(item => item.dateCreated), [props.projectSubmissionLogList]);

  const goToProjectSubmissionDetails = (e, id: number) => {
    if (e.target.nodeName !== 'IMG' && e.target.nodeName !== 'BUTTON') {
      history.push(`/project-submission-details/${id}`);
    }
  };

  const sortItems = [
    { name: 'Email', value: 'user.email' },
    { name: 'Project Code', value: 'project.projectCode' },
    { name: 'Project Name', value: 'project.projectName' },
    { name: 'Client Name', value: 'project.clientName' },
    { name: ' Date', value: 'dateCreated' },
  ];

  return (
    <Table responsive borderless hover className="leads-table project-submission-table">
      <thead className="project-submission-table-header">
        <tr className="project-submission-header">
          <th>
            <div className="d-flex align-items-center">
              <Suspense fallback={<span></span>}>
                <SortListDropdown items={sortItems} sort={props.sort} />
              </Suspense>
            </div>
          </th>
          <th>Email</th>
          <th>Project Code</th>
          <th>Project Name</th>
          <th>Client Name</th>
          <th>Date</th>
          {hasAnyAuthority(props.account.authorities, [AUTHORITIES.ROLE_FOOD_SERVICE_DRAWING, AUTHORITIES.ROLE_KROWNE_DRAWING]) && (
            <th>Drawings</th>
          )}
          <th>File Uploads</th>
          <th>Configuration</th>
          <th>Report</th>
        </tr>
      </thead>
      <tbody>
        {filteredList.map((item: IProjectSubmissionLog, index) => (
          <tr key={item.id} onClick={e => goToProjectSubmissionDetails(e, item.id)} className="project-submission-table-item">
            <td>{item.id}</td>
            <td>{item.email}</td>
            <td>{item.projectCode}</td>
            <td>{item.projectProjectName}</td>
            <td>{item.clientName}</td>
            <td>{moment(item.dateCreated).format('MMMM DD, YYYY, HH:mm a')}</td>
            <td className="project-submission-table-button-container">
              <Button
                className="project-submission-table-button"
                onClick={() => {
                  setIsDrawingsModalOpen(true);
                  setSelectedItem(item);
                }}
                disabled={!item.foodServiceUrl && !item.krowneDrawingUrl}
              >
                <Tippy
                  content="There are no Drawing files"
                  disabled={!!item.foodServiceUrl || !!item.krowneDrawingUrl}
                  placement="bottom"
                  theme="light-border"
                >
                  <img src="../../../content/images/icons/exportPdfBlue.svg" width="25" height="25" />
                </Tippy>
              </Button>
            </td>
            <td className="project-submission-table-button-container">
              {item.externalFileUrl && (
                <Button className="project-submission-table-button" onClick={() => window.open(item.externalFileUrl, '_blank')}>
                  <img src="../../../content/images/icons/download-blue-icon.svg" width="25" height="25" />
                </Button>
              )}
            </td>
            <td className="project-submission-table-button-container">
              <Button
                onClick={() => {
                  getCurrentProject(item.projectId);
                  setIsViewConfigurationButtonWasPressed(true);
                }}
              >
                <img src="../../../content/images/icons/configuration-cert-edit-icon.svg" width="25" height="25" />
              </Button>
            </td>

            <td className="project-submission-table-button-container">
              {item.reportUrl && (
                <Button className="project-submission-table-button" onClick={() => window.open(item.reportUrl, '_blank')}>
                  <img src="../../../content/images/icons/download-blue-icon.svg" width="25" height="25" />
                </Button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
      <Modal
        isOpen={isDrawingsModalOpen}
        centered
        style={{ maxWidth: 700, width: window.screen.width - 1050 }}
        toggle={() => setIsDrawingsModalOpen(false)}
        backdrop={true}
      >
        <div className="create-drawings-modal-container">
          {hasAnyAuthority(props.account.authorities, [AUTHORITIES.ROLE_FOOD_SERVICE_DRAWING]) && selectedItem.foodServiceUrl && (
            <button className="create-drawings-modal-button" onClick={() => window.open(selectedItem.foodServiceUrl, '_blank')}>
              <img src="../../../content/images/icons/marketplace-cart.svg" alt="marketplace cart" width="30" />
              Foodservice Drawings
            </button>
          )}

          {hasAnyAuthority(props.account.authorities, [AUTHORITIES.ROLE_KROWNE_DRAWING]) && selectedItem.krowneDrawingUrl && (
            <button className="create-drawings-modal-button" onClick={() => window.open(selectedItem.krowneDrawingUrl, '_blank')}>
              <img src="../../../content/images/icons/marketplace-cart.svg" alt="marketplace cart" width="30" />
              Krowne Drawings
            </button>
          )}
        </div>
      </Modal>
    </Table>
  );
});

export default ProjectSubmissionTable;
