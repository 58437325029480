// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Table } from 'reactstrap';
import { getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntitiesWithFilter, setFilter, resetFilters } from './product.reducer';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import ProductFilter from 'app/entities/product/ProductFilter';
import ReactPaginate from 'react-paginate';

export interface IProductProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}
const defaultFilters = {
  name: '',
  isMOC: false,
  isActive: false,
  visibleToAdmin: false,
  showInMarketPlace: false,
  revitExportEnable: false,
  cleanRevitFamily: false,
};
export const Product = (props: IProductProps) => {
  const ITEMS_PER_PAGE = 50;
  const [filterOpen, setFilterOpen] = useState(false);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(
      {
        itemsPerPage: ITEMS_PER_PAGE,
        sort: 'id',
        order: 'asc',
        activePage: 0,
      },
      props.location.search
    )
  );

  const getAllEntities = (activePage, itemsPerPage, sort, selectedFilters) => {
    // @ts-ignore
    props.getEntitiesWithFilter(activePage, itemsPerPage, sort, selectedFilters);
  };

  const sortEntities = () => {
    getAllEntities(
      paginationState.activePage,
      paginationState.itemsPerPage,
      `${paginationState.sort},${paginationState.order}`,
      props.filters
    );
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get('sort');
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage.selected,
    });

  const filterProjectSubmissionLog = () => {
    getAllEntities(
      paginationState.activePage - 1,
      paginationState.itemsPerPage,
      `${paginationState.sort},${paginationState.order}`,
      props.filters
    );
    setFilterOpen(false);
  };

  const onClear = () => {
    getAllEntities(
      paginationState.activePage - 1,
      paginationState.itemsPerPage,
      `${paginationState.sort},${paginationState.order}`,
      defaultFilters
    );
    props.resetFilters();
    setFilterOpen(false);
  };

  const style = {
    th: {
      top: 0,
      left: 0,
      zIndex: 2,
      position: 'sticky',
      backgroundColor: '#fff',
    },
  };

  const { productList, match, loading, totalItems } = props;
  return (
    <div>
      <h2 id="product-heading">
        Products
        <Button className="filter-button float-right ml-2" onClick={() => setFilterOpen(true)}>
          Filters
        </Button>
        <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
          &nbsp; Create new Product
        </Link>
      </h2>
      <div className="table-responsive products-table-container">
        {productList && productList.length > 0 ? (
          <Table responsive className="table table-fixed">
            <thead>
              <tr>
                <th style={style.th} className="hand product-table-header-item" onClick={sort('id')}>
                  ID <FontAwesomeIcon icon="sort" />
                </th>
                <th style={style.th} className="hand product-table-header-item">
                  Image
                </th>
                <th style={style.th} className="hand product-table-header-item">
                  Active?
                </th>
                <th style={style.th} className="hand product-table-header-item" onClick={sort('visibleToAdmin')}>
                  Admin Only <FontAwesomeIcon icon="sort" />
                </th>
                <th style={style.th} className="hand product-table-header-item" onClick={sort('manufacturer')}>
                  Manufacturer <FontAwesomeIcon icon="sort" />
                </th>
                <th style={style.th} className="hand product-table-header-item" onClick={sort('name')}>
                  Name <FontAwesomeIcon icon="sort" />
                </th>
                <th style={style.th} className="hand product-table-header-item">
                  Description
                </th>
                <th style={style.th} className="hand product-table-header-item" onClick={sort('roomleComponentId')}>
                  Roomle Component Id <FontAwesomeIcon icon="sort" />
                </th>
                <th style={style.th} className="hand product-table-header-item">
                  Root Component Id
                </th>
                <th style={style.th} className="hand product-table-header-item">
                  Revit Export
                </th>
                <th style={style.th} className="hand product-table-header-item">
                  Clean Revit
                </th>
                <th style={style.th} className="hand product-table-header-item" onClick={sort('complexFamily')}>
                  Is Complex Family <FontAwesomeIcon icon="sort" />
                </th>
                <th style={style.th} className="hand product-table-header-item" onClick={sort('showInMarketPlace')}>
                  Show In Market Place <FontAwesomeIcon icon="sort" />
                </th>
                <th style={{ ...style.th }} className="hand product-table-header-item"></th>
              </tr>
            </thead>
            <tbody>
              {productList.map((product, i) => (
                <tr key={`entity-${i}`}>
                  <td style={{ width: 50 }}>
                    <Button tag={Link} to={`${match.url}/${product.id}`} color="info" size="sm" style={{ marginBottom: 5, width: 70 }}>
                      <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">View</span>
                    </Button>
                    <Button
                      tag={Link}
                      to={`${match.url}/${product.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                      color="primary"
                      size="sm"
                      style={{ width: 70 }}
                    >
                      <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
                    </Button>
                    <Button tag={Link} to={`${match.url}/${product.id}`} color="link" size="sm">
                      {product.id}
                    </Button>
                  </td>
                  <td>
                    <img width={50} height={50} src={product.image ? product.image : ''} />
                  </td>

                  <td>{product.active ? 'Yes' : 'No'}</td>
                  <td>{product.visibleToAdmin ? 'Yes' : 'No'}</td>
                  <td>
                    {product.manufacturerName ? <Link to={`manufacturer/${product.manufacturerId}`}>{product.manufacturerName}</Link> : ''}
                  </td>
                  <td>{product.name}</td>
                  <td>{product.description}</td>
                  <td>{product.roomleComponentId}</td>
                  <td>{product.rootComponentId}</td>
                  <td>{product.revitExportEnable ? 'Yes' : 'No'}</td>
                  <td>{product.cleanRevitFamily ? 'Yes' : 'No'}</td>
                  <td>{product.complexFamily ? 'Yes' : 'No'}</td>
                  <td>{product.showInMarketPlace ? 'Yes' : 'No'}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`${match.url}/${product.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        style={{ width: 80 }}
                      >
                        <FontAwesomeIcon icon="trash" /> <span className="d-none d-md-inline">Delete</span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && <div className="alert alert-warning">No Products found</div>
        )}
      </div>
      {!props.loading && productList && productList.length > 0 && (
        <>
          <div className={productList && totalItems > ITEMS_PER_PAGE ? 'pagination-container' : 'd-none'}>
            <Row className="justify-content-center">
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePagination}
                pageRangeDisplayed={5}
                pageCount={Math.ceil(props.totalItems / ITEMS_PER_PAGE)}
                previousLabel="<"
                renderOnZeroPageCount={null}
                forcePage={paginationState.activePage}
                className="pagination"
                pageClassName="pagination-page"
                previousClassName="pagination-nex-prev-button"
                nextClassName="pagination-nex-prev-button"
                disabledClassName="pagination-page-disabled"
                activeClassName="pagination-active-page"
              />
            </Row>
          </div>
        </>
      )}

      <ProductFilter
        open={filterOpen}
        onClose={() => setFilterOpen(false)}
        selectedFilters={props.filters}
        setSelectedFilters={props.setFilter}
        onAction={filterProjectSubmissionLog}
        onClear={onClear}
      />
    </div>
  );
};

const mapStateToProps = ({ product }: IRootState) => ({
  productList: product.entities,
  loading: product.loading,
  totalItems: product.totalItems,
  filters: product.filters,
});

const mapDispatchToProps = {
  getEntitiesWithFilter,
  setFilter,
  resetFilters,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Product);
