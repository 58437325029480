import React, { memo } from 'react';
import ProjectSubmissionDetailsCollapse from './ProjectSubmissionDetailsCollapse';
import AppSpinner from '../Spinner/Spinner';
import { Table } from 'reactstrap';
import NoDataBanner from '../NoDataBanner/NoDataBanner';
import './projectSubmissionDetails.scss';

interface IProductsConfiguration {
  isProductsConfigurationCollapseOpen: boolean;
  setIsProductsConfigurationCollapseOpen: Function;
  partsListLoading: boolean;
  projectProductsPartsList: any;
}

const ProductsConfiguration = memo((props: IProductsConfiguration) => {
  return (
    <ProjectSubmissionDetailsCollapse
      title="Products Configuration"
      isOpen={props.isProductsConfigurationCollapseOpen}
      collapseToggle={props.setIsProductsConfigurationCollapseOpen}
    >
      <div className="project-settings-collapse-products-configurator-container">
        {props.partsListLoading ? (
          <AppSpinner />
        ) : props.projectProductsPartsList.length ? (
          props.projectProductsPartsList.map(item => (
            <div className="project-settings-collapse-products-configurator-container-item">
              <span className="project-submission-details-project-info-column-title">{item.configurationTitle}</span>
              <div style={{ marginBottom: 10 }}></div>
              <Table>
                <thead>
                  <tr>
                    <th className="project-submission-details-partslist-table-title">DESCRIPTION</th>
                    <th className="project-submission-details-partslist-table-title">MODEL</th>
                    <th className="project-submission-details-partslist-table-title">QUANTITY</th>
                  </tr>
                </thead>
                <tbody>
                  {item.partsList.map(item => (
                    <tr>
                      <td>{item.label}</td>
                      <td>{item.articleNr}</td>
                      <td>{item.count}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ))
        ) : (
          <NoDataBanner title="There are no Configurations in the Project!" />
        )}
      </div>
    </ProjectSubmissionDetailsCollapse>
  );
});

export default ProductsConfiguration;
