import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import './searchInput.scss';

const Search = props => {
  return (
    <FormGroup className="form-container">
      <Input
        placeholder={props.placeholder || 'Search'}
        type="search"
        className="search-input"
        value={props.searchValue}
        onChange={e => props.setSearchValue(e.target.value)}
        style={{ width: props.width }}
      />
      {!props.searchValue && <img src="../../../content/images/icons/search.svg" alt="search Icon" className="search-icon" />}
    </FormGroup>
  );
};

export default Search;
