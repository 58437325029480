import React, { useEffect, useState, lazy, Suspense, useCallback } from 'react';
import BaseLayout from '../BaseLayout';
import ProjectSubmissionTable from 'app/components/ProjectSubmissionTable/ProjectSubmissionTable';
import NoDataBanner from 'app/components/NoDataBanner/NoDataBanner';
import AppSpinner from 'app/components/Spinner/Spinner';
import { IRootState } from 'app/shared/reducers';
import { getEntities } from 'app/entities/project-submission-log/project-submission-log.reducer';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { getProject } from 'app/entities/project/project.reducer';
import './projectSubmission.scss';
const ProjectSubmissionFilter = lazy(() => import('app/pages/ProjectSubmission/ProjectSubmissionFilter'));
import { Button } from 'reactstrap';

const defaultFilters = {
  dateCreatedFrom: null,
  dateCreatedTo: null,
  email: [],
  guid: '',
  autoDeskId: '',
  jobId: '',
  projectCode: '',
  projectName: '',
  clientName: '',
};

const ProjectSubmission = props => {
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [selectedFilters, setSelectedFilters] = useState(defaultFilters);

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(
      {
        itemsPerPage: ITEMS_PER_PAGE,
        sort: 'dateCreated',
        order: 'desc',
        activePage: 0,
      },
      props.location.search
    )
  );

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get('sort');
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const sort = useCallback(
    p => {
      setPaginationState({
        ...paginationState,
        order: paginationState.order === 'asc' ? 'desc' : 'asc',
        sort: p,
      });
    },
    [paginationState]
  );

  const getAllEntities = (activePage, itemsPerPage, sort, selectedFilters) => {
    props.getEntities(activePage, itemsPerPage, sort, selectedFilters);
  };

  const sortEntities = () => {
    getAllEntities(
      paginationState.activePage - 1,
      paginationState.itemsPerPage,
      `${paginationState.sort},${paginationState.order}`,
      selectedFilters
    );
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  const handlePagination = currentPage => {
    setPaginationState({
      ...paginationState,
      activePage: currentPage.selected,
    });
  };

  const filterProjectSubmissionLog = () => {
    getAllEntities(
      paginationState.activePage - 1,
      paginationState.itemsPerPage,
      `${paginationState.sort},${paginationState.order}`,
      selectedFilters
    );
    setFilterOpen(false);
  };

  const onClear = () => {
    setSelectedFilters(defaultFilters);
    getAllEntities(
      paginationState.activePage - 1,
      paginationState.itemsPerPage,
      `${paginationState.sort},${paginationState.order}`,
      defaultFilters
    );
    setFilterOpen(false);
  };

  return (
    <BaseLayout title="Project Submission Log">
      <Button className="filter-button float-right" onClick={() => setFilterOpen(true)} style={{ marginBottom: 10 }}>
        Filters
      </Button>
      {props.projectSubmissionLogList.length && !props.loading ? (
        <>
          <ProjectSubmissionTable
            projectSubmissionLogList={props.projectSubmissionLogList}
            sort={sort}
            account={props.account}
            currentProject={props.currentProject}
            getProject={props.getProject}
          />
          <Row className="justify-content-center pagination-container">
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePagination}
              pageRangeDisplayed={5}
              pageCount={Math.ceil(props.totalItems / paginationState.itemsPerPage)}
              previousLabel="<"
              renderOnZeroPageCount={null}
              forcePage={paginationState.activePage}
              className="pagination"
              pageClassName="pagination-page"
              previousClassName="pagination-nex-prev-button"
              nextClassName="pagination-nex-prev-button"
              disabledClassName="pagination-page-disabled"
              activeClassName="pagination-active-page"
            />
          </Row>
        </>
      ) : null}
      {!props.projectSubmissionLogList.length && !props.loading ? (
        <NoDataBanner title="You have no Project Submissions to display yet!" />
      ) : null}
      {props.loading ? <AppSpinner /> : null}
      <Suspense fallback={<span></span>}>
        <ProjectSubmissionFilter
          open={filterOpen}
          onClose={() => setFilterOpen(false)}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          onAction={filterProjectSubmissionLog}
          onClear={onClear}
        />
      </Suspense>
    </BaseLayout>
  );
};

const mapStateToProps = ({ projectSubmissionLog, authentication, project }: IRootState) => ({
  projectSubmissionLogList: projectSubmissionLog.entities,
  totalItems: projectSubmissionLog.totalItems,
  loading: projectSubmissionLog.loading,
  account: authentication.account,
  currentProject: project.entity,
});

const mapDispatchToProps = {
  getEntities,
  getProject,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSubmission);
