import React, { useState, useEffect, Suspense, lazy } from 'react';
import BaseLayout from '../BaseLayout';
import Search from 'app/components/Search/Search';
import MainThemeButton from 'app/components/MainThemeButton/MainThemeButton';
import UserSettings from 'app/components/ProjectSubmissionDetails/UserSettings';
import ProductsConfiguration from 'app/components/ProjectSubmissionDetails/ProductsConfiguration';
import { Button, CardImg } from 'reactstrap';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import {
  getProjectSubmissionDetails,
  resetProjectSubmissionDetails,
} from 'app/entities/project-submission-log/project-submission-log.reducer';
import { getProject } from 'app/entities/project/project.reducer';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import AppSpinner from 'app/components/Spinner/Spinner';
import { usePartsList } from 'app/customHooks/usePartsList';
import useViewConfiguration from 'app/customHooks/useSharePageRedirect';
import { PROJECT_STATUSES_TRANSLATION } from 'app/config/constants';
import moment from 'moment';
import './projectSubmissionDetails.scss';

interface IProjectSubmissionDetails extends StateProps, DispatchProps, RouteComponentProps<{ projectId: string }> {
  details: any;
}

const ProjectSubmissionDetails = (props: IProjectSubmissionDetails) => {
  let history = useHistory();
  const { isDetailsOpen, setisDetailsOpen, projectProductsPartsList, partsListLoading } = usePartsList(
    props.details?.project?.projectProducts
  );
  const { setIsViewConfigurationButtonWasPressed, getCurrentProject } = useViewConfiguration(props);
  const [isUserSettingsCollapseOpen, setIsUserSettingsCollapseOpen] = useState<boolean>(false);

  useEffect(() => {
    props.getProjectSubmissionDetails(props.match.params.projectId);

    return () => {
      props.resetProjectSubmissionDetails();
    };
  }, []);

  const statusesColors = {
    IN_PROGRESS: 'yellow',
    COMPLETED: 'green',
    REJECTED: 'red',
    CLOSED: 'red',
  };

  return (
    <BaseLayout
      title={moment(props.details?.project?.lastModified).format('MMMM DD, YYYY, HH:mm a')}
      button={
        <div>
          <MainThemeButton
            iconName="edit-pencil-white"
            title="View Configuration"
            onClick={() => {
              getCurrentProject(props.details?.project?.id);
              setIsViewConfigurationButtonWasPressed(true);
            }}
          />
        </div>
      }
      search={<Search />}
    >
      {props.details === null || props.loading ? (
        <AppSpinner />
      ) : (
        <>
          <Button className="back-to-project-submission-button" onClick={() => history.push('/project-submission')}>
            Back to Project Submission Log
          </Button>
          {props.details.project.projectProducts.length > 1 && (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              {props.details.project.projectProducts.map(item => (
                <a
                  className="project-submission-log-configuration-link"
                  href={item.userConfiguration.baseURL + '?id=' + item.userConfiguration.configuration}
                  target="_blank"
                >
                  {item.userConfiguration.description}
                </a>
              ))}
            </div>
          )}
          <div className="project-settings-main-data-container">
            <span className="project-settings-project-name">{props.details.project?.projectName}</span>
            <div className="project-settings-project-subtitle-container">
              <span className="project-settings-project-subtitle">Shelving Layout Generic</span>
              <div className={`${statusesColors[props.details.project?.status]} project-settings-project-status`}>
                {PROJECT_STATUSES_TRANSLATION[props.details.project.status]}
              </div>
            </div>
            {props.details.project?.thumbnailImage && (
              <CardImg top src={props.details.project.thumbnailImage} className="project-settings-project-image" />
            )}
          </div>
          <div className="project-submission-details-project-info">
            <div className="project-submission-details-project-info-column">
              <div className="project-submission-details-project-info-column-row">
                <span className="project-submission-details-project-info-column-row-data">Client Name :</span>
                <span className="project-submission-details-project-info-column-row-data">{props.details.project?.clietnName}</span>
              </div>
              <div className="project-submission-details-project-info-column-row">
                <span className="project-submission-details-project-info-column-row-data">Date Created :</span>
                <span className="project-submission-details-project-info-column-row-data ">
                  {moment(props.details.project?.dateCreated).format('MMMM DD, YYYY')}
                </span>
              </div>
              <div className="project-submission-details-project-info-column-row">
                <span className="project-submission-details-project-info-column-row-data">Last Modified :</span>
                <span className="project-submission-details-project-info-column-row-data">
                  {moment(props.details.project?.lastModified).format('MMMM DD, YYYY, HH:mm a')}
                </span>
              </div>
              <div className="project-submission-details-project-info-column-row">
                <span className="project-submission-details-project-info-column-row-data">Project Street Address :</span>
                <span className="project-submission-details-project-info-column-row-data">
                  {props.details.project?.projectStreetAddress}
                </span>
              </div>
              <div className="project-submission-details-project-info-column-row">
                <span className="project-submission-details-project-info-column-row-data">Project City :</span>
                <span className="project-submission-details-project-info-column-row-data">{props.details.project?.projectCity}</span>
              </div>
              <div className="project-submission-details-project-info-column-row">
                <span className="project-submission-details-project-info-column-row-data">Project State :</span>
                <span className="project-submission-details-project-info-column-row-data">{props.details.project?.projectState}</span>
              </div>
              <div className="project-submission-details-project-info-column-row">
                <span className="project-submission-details-project-info-column-row-data">Project Zip Code :</span>
                <span className="project-submission-details-project-info-column-row-data">{props.details.project?.projectZipCode}</span>
              </div>
            </div>
            <div className="project-submission-details-project-info-column">
              {props.details.project.user.imageUrl && (
                <div className="project-submission-details-project-info-column-row">
                  <span className="project-submission-details-project-info-column-row-data">User Logo File :</span>
                  <CardImg
                    alt="Missing Image"
                    src={props.details.project.user.imageUrl}
                    className="project-submission-details-conpany-logo"
                  />
                </div>
              )}
              <div className="project-submission-details-project-info-column-row">
                <span className="project-submission-details-project-info-column-row-data">User Company Name :</span>
                <span className="project-submission-details-project-info-column-row-data ">{props.details.project?.userCompanyName}</span>
              </div>
              <div className="project-submission-details-project-info-column-row">
                <span className="project-submission-details-project-info-column-row-data">User Street Address :</span>
                <span className="project-submission-details-project-info-column-row-data">{props.details.project?.userStreetAddress}</span>
              </div>
              <div className="project-submission-details-project-info-column-row">
                <span className="project-submission-details-project-info-column-row-data">User City :</span>
                <span className="project-submission-details-project-info-column-row-data">{props.details.project?.userCity}</span>
              </div>
              <div className="project-submission-details-project-info-column-row">
                <span className="project-submission-details-project-info-column-row-data">User State :</span>
                <span className="project-submission-details-project-info-column-row-data">{props.details.project?.userState}</span>
              </div>

              <div className="project-submission-details-project-info-column-row">
                <span className="project-submission-details-project-info-column-row-data">User Company Website URL :</span>
                <a href={props.details.project?.userCompanyUrl}>{props.details.project?.userCompanyUrl}</a>
              </div>
              <div className="project-submission-details-project-info-column-row">
                <span className="project-submission-details-project-info-column-row-data">User Phone Number :</span>
                <span className="project-submission-details-project-info-column-row-data">{props.details.project?.userPhone}</span>
              </div>
            </div>
          </div>
          <div className="project-submission-details-project-info collapse-container">
            <UserSettings
              isUserSettingsCollapseOpen={isUserSettingsCollapseOpen}
              setIsUserSettingsCollapseOpen={setIsUserSettingsCollapseOpen}
              details={props.details}
            />
            <ProductsConfiguration
              isProductsConfigurationCollapseOpen={isDetailsOpen}
              setIsProductsConfigurationCollapseOpen={setisDetailsOpen}
              partsListLoading={partsListLoading}
              projectProductsPartsList={projectProductsPartsList}
            />
          </div>
        </>
      )}
    </BaseLayout>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  details: storeState.projectSubmissionLog.projectSubmissionDetails,
  loading: storeState.projectSubmissionLog.loadingDetails,
  currentProject: storeState.project.entity,
});

const mapDispatchToProps = {
  getProjectSubmissionDetails,
  resetProjectSubmissionDetails,
  getProject,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSubmissionDetails);
