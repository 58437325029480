import React from 'react';
import { Collapse, Button } from 'reactstrap';
import './projectSubmissionDetails.scss';

interface IProjectSubmissionDetailsCollapse {
  isOpen: boolean;
  children?: any;
  title: string;
  collapseToggle: Function;
}

const ProjectSubmissionDetailsCollapse = (props: IProjectSubmissionDetailsCollapse) => {
  return (
    <>
      <div className="project-submission-collapse-bar" onClick={() => props.collapseToggle(!props.isOpen)}>
        <div className="project-submission-collapse-bar-container">
          <span className="project-submission-collapse-bar-name">{props.title}</span>
        </div>

        <Button className="project-submission-collapse-bar-open-button">
          {props.isOpen ? (
            <img src="../../../content/images/icons/folder-minus.svg" className="close-folde-image" alt="open folder button" />
          ) : (
            <img src="../../../content/images/icons/folder-plus.svg" className="open-folde-image" alt="open folder button" />
          )}
        </Button>
      </div>
      <Collapse isOpen={props.isOpen}>{props.children}</Collapse>
    </>
  );
};

export default ProjectSubmissionDetailsCollapse;
