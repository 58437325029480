// @ts-nocheck
import React, { forwardRef } from 'react';
import { FormGroup, Input } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './datePicker.scss';

interface IDatePickerFilter {
  startDate: any;
  setStartDate: Function;
}

const DatePickerFilter = (props: IDatePickerFilter) => {
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <FormGroup className="form-container" onClick={onClick} ref={ref}>
      <Input placeholder="Search" className="search-input" value={value} style={{ color: '#717888' }} />
      <img src="../../../content/images/icons/datePickerIcon.svg" alt="search Icon" className="search-icon" style={{ marginTop: -2 }} />
    </FormGroup>
  ));

  return (
    <DatePicker
      selected={props.startDate}
      onChange={date => props.setStartDate(date)}
      customInput={<CustomInput />}
      dateFormat="MMM, yyyy"
      showMonthYearPicker
    />
  );
};

export default DatePickerFilter;
