import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Row } from 'reactstrap';
import { JhiPagination, JhiItemCount } from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import { getVersionByReleaseType, downloadVersionFile } from './version.reducer';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import DownloadsTable from 'app/components/DownloadsTable/DownloadsTable';
import Toggle from 'app/components/Toggle/Toggle';
import { VERSIONS } from 'app/config/constants';
import './versions.scss';

export interface IVersionProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const VersionAll = (props: IVersionProps) => {
  const [releaseType, setReleaseType] = useState(VERSIONS.CURRENT_VERSION);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(
      {
        itemsPerPage: ITEMS_PER_PAGE,
        sort: 'releaseDate',
        order: 'desc',
        activePage: 1,
      },
      props.location.search
    )
  );

  const getAllEntities = () => {
    props.getVersionByReleaseType(
      releaseType,
      paginationState.activePage - 1,
      paginationState.itemsPerPage,
      `${paginationState.sort},${paginationState.order}`
    );
  };

  const downloadVersion = (filePath: string) => {
    window.location.href = filePath;
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, releaseType]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get('sort');
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const { versionList, loading } = props;
  return (
    <div className={props.account.login ? ' ' : 'unauthorised-versions-container'}>
      {!props.account.login && (
        <img src="../../../content/images/kreator-navbar-logo.png" alt="logo" width="100" className="verions-page-logo" />
      )}
      <Toggle
        rightButtonTitle="Past Versions"
        leftButtonTitle="Current Version"
        loading={loading}
        rightButtonAction={() => setReleaseType(VERSIONS.PAST_VERSION)}
        leftButtonAction={() => setReleaseType(VERSIONS.CURRENT_VERSION)}
        rightButtonActive={releaseType === VERSIONS.PAST_VERSION}
        leftButtonActive={releaseType === VERSIONS.CURRENT_VERSION}
      />
      <div className="mb-4"></div>
      <DownloadsTable versions={versionList} downloadVersion={downloadVersion} />
      {props.totalItems ? (
        <div className={versionList && versionList.length > paginationState.itemsPerPage ? 'pagination-container' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={props.totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = ({ version, authentication }: IRootState) => ({
  versionList: version.entities,
  loading: version.loading,
  totalItems: version.totalItems,
  account: authentication.account,
});

const mapDispatchToProps = {
  getVersionByReleaseType,
  downloadVersionFile,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VersionAll);
