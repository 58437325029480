import React, { memo } from 'react';
import ProjectSubmissionDetailsCollapse from './ProjectSubmissionDetailsCollapse';
import { Button } from 'reactstrap';
import './projectSubmissionDetails.scss';

interface IUserSettings {
  isUserSettingsCollapseOpen: boolean;
  setIsUserSettingsCollapseOpen: Function;
  details: any;
}

const UserSettings = memo((props: IUserSettings) => {
  return (
    <ProjectSubmissionDetailsCollapse
      title="User Settings"
      isOpen={props.isUserSettingsCollapseOpen}
      collapseToggle={props.setIsUserSettingsCollapseOpen}
    >
      <div className="project-settings-collapse-container">
        <div className="project-submission-details-project-info collapsed-project-info">
          <div className="project-submission-details-project-info-column">
            <div className="project-submission-details-project-info-column-row">
              <span className="project-submission-details-project-info-column-row-data">Name:</span>
              <span className="project-submission-details-project-info-column-row-data">
                {props.details.user.firstName + ' ' + props.details.user.lastName}
              </span>
            </div>
            <div className="project-submission-details-project-info-column-row">
              <span className="project-submission-details-project-info-column-row-data">Email:</span>
              <span className="project-submission-details-project-info-column-row-data email">{props.details.user.email}</span>
            </div>

            <div className="project-submission-details-project-info-column-row">
              <span className="project-submission-details-project-info-column-row-data">Project Code:</span>
              <span className="project-submission-details-project-info-column-row-data">{props.details.user.project?.projectCode}</span>
            </div>
            <div className="project-submission-details-project-info-column-row">
              <span className="project-submission-details-project-info-column-row-data">Project Name:</span>
              <span className="project-submission-details-project-info-column-row-data">{props.details.user.project?.projectName}</span>
            </div>
            {props.details?.project?.externalFileUrl && (
              <div className="project-submission-details-project-info-column-row">
                <span className="project-submission-details-project-info-column-row-data">File Uploads:</span>
                <span className="project-submission-details-project-info-column-row-data">
                  <Button
                    className="project-submission-table-button"
                    onClick={() => window.open(props.details?.project?.externalFileUrl, '_blank')}
                  >
                    <img src="../../../content/images/icons/download-blue-icon.svg" width="25" height="25" />
                  </Button>
                </span>
              </div>
            )}
          </div>
          <div className="project-submission-details-project-info-column">
            <div className="project-submission-details-project-info-column-row">
              <span className="project-submission-details-project-info-column-row-data">Project Zip Code:</span>
              <span className="project-submission-details-project-info-column-row-data">{props.details.user.project?.projectZipCode}</span>
            </div>
            <div className="project-submission-details-project-info-column-row">
              <span className="project-submission-details-project-info-column-row-data">Project File:</span>
              <span className="project-submission-details-project-info-column-row-data project-file">N/A</span>
            </div>
            <div className="project-submission-details-project-info-column-row">
              <span className="project-submission-details-project-info-column-row-data">Company:</span>
              <span className="project-submission-details-project-info-column-row-data">{props.details.user.company}</span>
            </div>
            <div className="project-submission-details-project-info-column-row">
              <span className="project-submission-details-project-info-column-row-data">Market Segment:</span>
              <span className="project-submission-details-project-info-column-row-data">{props.details.project.marketSegment}</span>
            </div>
          </div>
        </div>
      </div>
    </ProjectSubmissionDetailsCollapse>
  );
});

export default UserSettings;
